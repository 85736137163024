<template>
  <div class="rent-detail-view">
    <page-block :title="$t('title.plan')" class="rent-detail-view__block">
      <main-card>
        <div class="standart-title">
          {{ `${tariff.pricelist} (${$tc('period.month_c', tariff.period)})` }}
        </div>
        <div class="rent-detail-view__promise">
          <div class="note-text">
            {{ $t('activeUnder', { date: $d(tariff.expiredate, 'short') }) }}
          </div>
          <plain-button
            v-if="tariff.promise !== 'null'"
            v-tooltip="{
              content: promiseOn,
              autoHide: false,
              placement: 'top-start',
              container: false,
            }"
            icon="promise-icon"
            class="allowed-user-rights__promise"
          />
        </div>
        <template v-if="hasProlongTool || hasChangepricelistTool" #bodyEnd>
          <div class="tariff-plan__btns">
            <base-button
              v-if="hasProlongTool"
              class="tariff-plan__btn"
              @click="
                tariff.period_cost === '0.00' ? singleProlongFree(tariff) : singleProlong(tariff)
              "
            >
              {{ $t('actions.prolong') }}
            </base-button>
            <base-button
              v-if="hasChangepricelistTool"
              theme="outlined"
              class="tariff-plan__btn"
              @click="changepricelist"
            >
              {{ $t('actions.changePlan') }}
            </base-button>
          </div>
        </template>
      </main-card>
    </page-block>
    <page-block
      v-if="hasAutoprolong && autoprolongSettings"
      :title="$t('title.autoprolong')"
      class="rent-detail-view__block"
    >
      <main-card>
        <div class="standart-text">
          {{
            $t(`autoprolong.state.${autoprolongSettings.state}`, {
              period: autoprolongSettings.period,
            })
          }}
        </div>
        <template #bodyEnd>
          <div class="tariff-autoprolong__btns">
            <base-button
              class="tariff-autoprolong__btn"
              @click="autoprolong(tariff, autoprolongSettings.state === 'on' ? 'update' : 'on')"
            >
              {{
                autoprolongSettings.state === 'on'
                  ? $t('autoprolong.actions.update')
                  : $t('autoprolong.actions.on')
              }}
            </base-button>
            <base-button
              v-if="autoprolongSettings.state === 'on'"
              theme="outlined"
              class="tariff-autoprolong__btn"
              @click="autoprolong(tariff, 'off')"
            >
              {{ $t('autoprolong.actions.off') }}
            </base-button>
          </div>
        </template>
      </main-card>
    </page-block>
    <transition name="bubble">
      <page-block v-if="addons.length" :title="$t('title.addons')" class="rent-detail-view__block">
        <main-card>
          <div class="addons">
            <div v-for="(item, i) in addons" :key="i" class="addons__item">
              <div class="addons__label standart-title">
                {{ item.label }}
              </div>
              <div class="addons__value standart-text">
                {{ item.value }}
              </div>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import PageBlock from '../components/PageBlock';
import MainCard from '@/components/MainCard/MainCard';
import RentProlongSingle from '../../../components/RentProlongSingle.vue';
import { RentTariff } from '@/models/BillMgr/RentTariff';
import changePricelist from '@/mixins/billmgr/changePricelist';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
import prolongAuto from '@/mixins/billmgr/prolongAuto';
import RentProlongSinglePayments from '@/layouts/RentServer/components/RentProlongSinglePayments';
import prolongText from '@/mixins/prolongText';
export default {
  name: 'RentView',
  components: {
    PageBlock,
    MainCard,
  },
  mixins: [changePricelist, prolongSingle, prolongSingleFree, prolongAuto, prolongText],
  props: {
    tariff: {
      type: RentTariff,
      required: true,
      validator: obj => obj instanceof RentTariff,
    },
  },
  data() {
    return {
      moduleMain: 'moduleRent',
      moduleOrder: 'moduleRentOrder',
      itemtype: 'rps',
      singleProlongComponentFirst: RentProlongSingle,
      singleProlongComponent: RentProlongSinglePayments,
    };
  },
  computed: {
    addons() {
      if (!this.tariff.addons) return [];
      return Object.values(this.tariff.addons).map(addon => {
        let item = {
          label: addon.desc,
          value: addon.value,
        };
        if (addon.measure) item.value += ` ${addon.measure}`;
        return item;
      });
    },
    tools() {
      return this.$store.state.moduleRent.tools;
    },
    cost() {
      return this.tariff.cost;
    },
    hasChangepricelistTool() {
      return (
        !!this.tools &&
        !!this.tools.changepricelist &&
        this.tools.changepricelist.isEnable(this.tariff)
      );
    },
    hasProlongTool() {
      return (
        this.tariff.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.tariff)
      );
    },
    hasAutoprolong() {
      return this.tariff.autoprolongAvailable;
    },
    autoprolongSettings() {
      return this.tariff.autoprolongSettings;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "promise" : "Для услуги активирован обещанный платеж до {promise}",
      "plan": "Тарифный план",
      "addons": "Дополнительная информация",
      "stats": "Статистика",
      "autoprolong": "Автопродление"
    },
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    },
    "changepricelist": {
      "title": "Смена тарифа",
      "success": "Смена тарифа прошла успешно. Данные о тарифе скоро обновятся."
    },
    "prolong": {
      "title": "Продление тарифа",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифа успешно добавлено в корзину",
      "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
      "free": "Активировать",
      "cancel": "Отмена",
      "confirm": "Оплатить",
      "order": "В корзину"
    },
    "autoprolong": {
      "title": "Автопродление",
      "state": {
        "on": "Включено автоматическое продление на {period}",
        "off": "Автоматическое продление выключено"
      },
      "actions": {
        "on": "Включить",
        "off": "Выключить",
        "update": "Изменить"
      },
      "modals": {
        "off": {
          "title": "Отключить автопроделние",
          "desc": "Автопродление для услуги #{id} будет отключено",
          "confirm": "Да, отключить"
        },
        "update": {
          "title": "Изменить настройки автопродления",
          "confirm": "Сохранить"
        },
        "on": {
          "title": "Включить автопродление",
          "confirm": "Включить"
        }
      },
      "result": {
        "success": {
          "title": "Отправлено успешно",
          "desc": "Настройки автопродления для услуги #{id} были успешно изменены",
          "confirm": "Хорошо"
        },
        "error": {
          "title": "Произошла ошибка",
          "desc": "Настройки автопродления для услуги #{id} изменить не удалось. Попробуйте позднее или обратитесь за помощью в поддержку",
          "confirm": "Понятно"
        }
      }
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.rent-detail-view{
  &__promise{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}
.allowed-user-rights{
  &__promise{
    margin-left: 1px;
  }
}
.tariff {
  &-plan,
  &-autoprolong {

    &__btn {
      min-width: 160px;
      & + & {
        margin-top: 14px;

        +breakpoint(sm-and-up) {
          margin-top: 0;
          margin-left: 1.5rem;
        }
      }
    }
  }
}

.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
